import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import contactStyles from './contact.module.scss'

const ContactPage = () => {
    return (
        <Layout>
            <SEO
                pagetitle="Contact"
                pageDesc="Contact Aiki Ishin-ryu from here."
            />
            <div className={contactStyles.contact}>
                <h1>Contact</h1>
                <p>We use Google Forms to receive contact. Please fill in the form below.</p>
                <div className={contactStyles.googleforms}>
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSehnYGCDgPi5fKx8s6_02wD1Ro4KxTk3jHAgGWl4OIGerkwxA/viewform?embedded=true"  width="100%" height="3300" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage